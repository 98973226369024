<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <tabs
          @tab-selected="reloadList"
          :tabType="this.$route.path.indexOf('clinicDetail') !== -1 ? 0 : 1"
        >
          <tab :tabData="tab[0]" v-if="view.isVisible">
            <tab-list
              :listType="listCondition[0].type"
              :result="listCondition[0].result"
              v-if="viewTab[0].flag"
            ></tab-list>
          </tab>
          <tab :tabData="tab[1]" v-if="view.isVisible">
            <tab-list
              :listType="listCondition[1].type"
              :result="listCondition[1].result"
              :isEmpty="view.isEmpty"
              v-if="viewTab[1].flag"
            ></tab-list>
          </tab>
          <!-- <tab :tabData="tab[2]" v-if="view.isVisible">
            <p v-if="viewTab[2].flag">준비중입니다.</p>
          </tab> -->
        </tabs>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import TabList from '@/components/TabList.vue';
import Tabs from '@/components/Tabs.vue';
import Tab from '@/components/Tab.vue';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';

import {
  mapMutations, mapState, mapGetters, mapActions,
} from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      view: {
        isEmpty: false,
      },
      listCondition: [
        {
          type: 0,
          result: {
            structure: {
              dayList: [],
            },
            water: {
              dayList: [],
            },
            solid: {
              dayList: [],
            },
            vocalize: {
              dayList: [],
            },
          },
        },
        {
          type: 1,
          result: {
            structure: {
              dayList: [],
            },
            water: {
              dayList: [],
            },
            solid: {
              dayList: [],
            },
            vocalize: {
              dayList: [],
            },
          },
        },
        {
          type: 2,
          result: {
            structure: {
              dayList: [],
            },
            water: {
              dayList: [],
            },
            solid: {
              dayList: [],
            },
            vocalize: {
              dayList: [],
            },
          },
        },
      ],
      tab: [
        {
          title: '삼킴 임상 평가',
        },
        {
          title: '삼킴 설문',
        },
      ],
      tree: {
        title: '삼킴 평가',
        detail: '삼킴 임상 평가 리스트',
        detail2: '평가별 상세정보',
      },
      viewTab: [
        {
          flag: false,
        },
        {
          flag: false,
        },
        {
          flag: false,
        },
      ],
      view: {
        isVisible: false,
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getParams();
  },
  methods: {
    ...mapActions('evaluate', ['FETCH_EVALUATION_DETAIL', 'FETCH_SURVEY_DETAIL']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    getParams() {
      console.log('회원 정보 파람', this.$route.params.code);
      console.log('날짜 파람', this.GET_DAY);
    },
    reloadList(order) {
      console.log('현재 몇번째 순번 탭? :', order);
      if (order === 0) {
        this.getEvalutateResult(0);
      } else if (order === 1) {
        this.getSurveyResult(1);
      }
      this.view.isVisible = true;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    async getEvalutateResult(order) {
      /** ID, 정보로  평가를 진행한 날짜리스트를 조회함 */
      try {
        this.requestParam = `?userId=${this.$route.params.code}&type=all&get_type=day`;
        await this.FETCH_EVALUATION_DETAIL(this.requestParam).then((data) => {
          const allday = data.response.structure.concat(
            data.response.water,
            data.response.solid,
            data.response.vocalize,
          );
          const uniqueday = allday.filter(this.onlyUnique);
          uniqueday.sort((a, b) => {
            if (a < b) return 1;
            if (a > b) return -1;
            if (a === b) return 0;
          });

          /** 셀렉트박스 선택일로 가져온 구조 데이터 */
          const getResult = {
            structure: {
              dayList: uniqueday,
            },
            water: {
              dayList: uniqueday,
            },
            solid: {
              dayList: uniqueday,
            },
            vocalize: {
              dayList: uniqueday,
            },
          };

          this.listCondition[order].result = getResult;
          this.viewTab[order].flag = true;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    async getSurveyResult(order) {
      /** ID, 정보로  평가를 진행한 날짜리스트를 조회함 */
      try {
        this.requestParam = `?userId=${this.$route.params.code}&get_type=day`;
        await this.FETCH_SURVEY_DETAIL(this.requestParam).then((data) => {
          if (data.response.list.length > 0) {
            const allday = data.response.list;
            const uniqueday = allday.filter(this.onlyUnique);
            uniqueday.sort((a, b) => {
              if (a < b) return 1;
              if (a > b) return -1;
              if (a === b) return 0;
            });

            /** 셀렉트박스 선택일로 가져온 구조 데이터 */
            const getResult = {
              survey: {
                dayList: uniqueday,
              },
            };

            console.log('survey=explain', getResult);

            this.listCondition[order].result = getResult;
            this.viewTab[order].flag = true;
          } else {
            this.view.isEmpty = true;
            this.viewTab[order].flag = true;
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    ...mapGetters('evaluate', ['GET_DAY']),
  },
  components: {
    SideMenu,
    Notification,
    TabList,
    Tabs,
    Tab,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.tab-box {
  &.clinic {
  }
}
</style>
