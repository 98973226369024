<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h3 class="notice">설문 결과물 확인</h3>
        <div class="explain-list">
          <div class="header">
            <div class="item">
              <span>순서</span>
            </div>
            <div class="item">
              <span>설문 내용</span>
            </div>
            <div class="item">
              <span>답변</span>
            </div>
          </div>
          <div class="content">
            <div
              class="item"
              v-for="(parentItem, parentIndex) in explainList"
              :key="parentIndex + 'A'"
            >
              <div class="i-order">
                <span v-text="parentIndex + 1" />
              </div>
              <div class="i-header">
                <span v-text="parentItem.questionText" />
              </div>
              <div class="i-content">
                <span
                  v-for="(childItem, childIndex) in parentItem.answerList"
                  :key="childIndex + 'B'"
                  v-text="childItem.answerText"
                  :class="{ selected: parentItem.selected === childIndex }"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '삶의의미 탐색',
        detail: '설문 결과물 확인',
      },
      explainList: [],
    };
  },
  created() {
    console.log(this.$route.query);
    console.log(this.$route.query.MeaningExplain);
    window.scrollTo(0, 0);
    this.getData(this.$route.query.MeaningExplain);
  },
  methods: {
    ...mapActions('activity', ['FETCH_LIFE_RESULT']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    getData(info) {
      console.log(`info : ${info}`);
      this.FETCH_LIFE_RESULT(`?id=${info.id}&day=${info.day}`).then((data) => {
        this.explainList = data.response.list;
      });
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
h3.notice {
  margin: 0 0 15px 0;
  @include initfontKor(
    $font-size: 26px,
    $line-height: 58px,
    $font-family: "AppleSDGothicNeoB00",
    $color: #292a2b,
    $letter-spacing: -0.6px,
    $text-align: left
  );
}
.explain-list {
  margin: 0 0 60px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    margin: 0 0 1px 0;
    grid-template-columns: 90px 430px 1fr;
    background: #fd8b11;

    .item {
      outline: 1px solid #292a2b;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        @include initfontKor(
          $font-size: 18px,
          $line-height: 42px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    .item {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 90px 430px 1fr;
      margin: 1px 0 0 0;
      .i-header {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 1px solid #292a2b;
      }
      .i-order {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 1px solid #292a2b;
        span {
          display: block;
          @include initfontKor(
            $font-size: 18px,
            $line-height: 42px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
      .i-content {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: 1px solid #292a2b;
      }
      span {
        position: relative;
        padding: 0 6px;
        @include initfontKor(
          $font-size: 18px,
          $line-height: 42px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        &:after {
          content: "/";
          position: absolute;
          left: -1px;
        }
        &.selected {
          color: #f36900;
        }
        &:first-of-type {
          padding: 0 6px 0 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
