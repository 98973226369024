import axios from "axios";

const domain = process.env.VUE_APP_API_URL;
const request = {
  get(path, header) {
    const currentUrl = document.location.href;
    console.log("get Api Current URL", currentUrl);
    console.log("get Path", path);
    return axios
      .get(`${domain + path}`, header)
      .catch(error => {
        console.log("domain", domain);
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            console.log("AAAAAAAAAAAAAAAAAAAAAA", window.app);
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("storeId");
            // const url = "samkim://logout";
            // const url = "/login";
            // document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[localStorage] 1.get : ", localStorage);
      });
  },
  patch(path, data, header) {
    const currentUrl = document.location.href;
    console.log("patch Api Current URL", currentUrl);
    console.log("patch Path", path);
    return axios
      .patch(`${domain + path}`, data, header)
      .catch(error => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[localStorage] 2.patch : ", localStorage);
      });
  },
  post(path, data, header) {
    const currentUrl = document.location.href;
    console.log("post Api Current URL", currentUrl);
    console.log("post Path", path);
    return axios
      .post(`${domain + path}`, data, header)
      .catch(error => {
        console.log("찐에러", error);
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[localStorage] 3.post : ", localStorage);
      });
  },
  delete(path, header) {
    const currentUrl = document.location.href;
    console.log("delete Api Current URL", currentUrl);
    console.log("delete Path", path);
    return axios
      .delete(`${domain + path}`, header)
      .catch(error => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[localStorage] 4.delete : ", localStorage);
      });
  },
  put(path, data, header) {
    const currentUrl = document.location.href;
    console.log("put Api Current URL", currentUrl);
    console.log("put Path", path);
    return axios
      .put(`${domain + path}`, data, header)
      .catch(error => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
          }
          if (error.response.statusText === "Unauthorized") {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("userName");
            localStorage.removeItem("storeId");
            const url = "/login";
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우identitySuccess
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        console.log("[localStorage] 5.put : ", localStorage);
      });
  }
};

export const auth = {};
export const activity = {
  logOut(userInfo) {
    return request
      .post("/account/logout", userInfo, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchLifeList(apiParam) {
    return request
      .get(`/activity/life/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchLifeDetail(apiParam) {
    return request
      .get(`/activity/life/detail${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchLifeResult(apiParam) {
    return request
      .get(`/activity/life/result${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchPFList(apiParam) {
    return request
      .get(`/activity/pf/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchPFDetail(apiParam) {
    return request
      .get(`/activity/pf/detail${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchPFResult(apiParam) {
    return request
      .get(`/activity/pf/result${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchPFResultImage(apiParam) {
    return request
      .post(`/activity/pf/result`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        },
        responseType: "blob"
      })
      .then(({ data }) => data);
  }
};
export const util = {
  fetchLogin(apiParam) {
    return request
      .post(`/admin_account/login/`, apiParam, {
        headers: {}
      })
      .then(({ data }) => data);
  },
  Logout(apiParam) {
    return request
      .post("/admin_account/logout", apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(data => data);
  },
  fetchNoticeList(apiParam) {
    return request
      .get(`/setting/notice/list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchNotice(apiParam) {
    return request
      .get(`/setting/notice/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  insertNotice(apiParam) {
    return request
      .post(`/setting/notice/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data"
        },
        contentType: "multipart/form-data"
      })
      .then(({ data }) => data);
  },
  updateNotice(apiParam) {
    return request
      .put(`/setting/notice/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data"
        },
        contentType: "multipart/form-data"
      })
      .then(({ data }) => data);
  },
  deleteNotice(apiParam) {
    return request
      .delete(`/setting/notice/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchAppVersion() {
    return request
      .get("/setting/app", {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  insertAppVersion(apiParam) {
    return request
      .post("/setting/app", apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateAppVersion(apiParam) {
    return request
      .put("/setting/app", apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  sendPush(apiParam) {
    return request
      .post("/push", apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  }
};
export const member = {
  fetchAccountList(apiParam) {
    return request
      .get(`/account/list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchAccountDetail(apiParam) {
    return request
      .get(`/account/detail/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  postAccountDetail(apiParam) {
    return request
      .post(`/account/detail/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchAccountSetList(apiParam) {
    return request
      .get(`/account/set/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateAccountSetMember(apiParam) {
    return request
      .put(`/account/set/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchActivityPositionList(apiParam) {
    return request
      .get(`/activity/position_list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateActivityPosition(apiParam) {
    return request
      .post(`/activity/position_list/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchActivityFeedingList(apiParam) {
    return request
      .get(`/activity/feeding_list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateActivityFeeding(apiParam) {
    return request
      .post(`/activity/feeding_list/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchManageList(apiParam) {
    return request
      .get(`/admin_account/admin_list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchManageDetail(apiParam) {
    return request
      .get(`/admin_account/admin_detail/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  postManageDetail(apiParam) {
    return request
      .post(`/admin_account/admin_detail/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  deleteManageDetail(apiParam) {
    return request
      .delete(`/admin_account/admin_detail/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultList(apiParam) {
    return request
      .get(`/admin_account/consult_list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultDetail(apiParam) {
    return request
      .get(`/admin_account/consult_detail/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultSign(apiParam) {
    return request
      .post("/admin_account/consult_image/", apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        },
        responseType: "blob"
      })
      .then(({ data }) => data);
  },
  postConsultDetail(apiParam) {
    return request
      .post(`/admin_account/consult_detail/`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data"
        },
        contentType: "multipart/form-data"
      })
      .then(({ data }) => data);
  }
};
export const evaluate = {
  fetchEvaluationList(apiParam) {
    return request
      .get(`/evaluation/list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchEvaluationDetail(apiParam) {
    return request
      .get(`/evaluation/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchSurveyList(apiParam) {
    return request
      .get(`/evaluation/survey/list/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchSurveyDetail(apiParam) {
    return request
      .get(`/evaluation/survey/${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  }
};
export const training = {
  fetchTrainingList(apiParam) {
    return request
      .get(`/training/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchTraining(apiParam) {
    return request
      .get(`/training${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchTrainingDate(apiParam) {
    return request
      .get(`/training/date${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchTrainingHertz(apiParam) {
    return request
      .get(`/training/hertz${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchTrainingVideo(userId) {
    return request
      .get(`/training/video?userId=${userId}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },

  checkTraining(apiParam) {
    return request
      .get(`/training/check${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  }
};
export const reservation = {
  fetchConsultation(apiParam) {
    return request
      .get(`/consultation${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  startConsultation(apiParam) {
    return request
      .post(`/consultation/start`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  storeConsultation(apiParam) {
    return request
      .post(`/consultation`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultationList(apiParam) {
    return request
      .get(`/consultation/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateConsultation(apiParam) {
    return request
      .put("/consultation", apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultationAvailableList(apiParam) {
    return request
      .get(`/consultation/available${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultationLimit() {
    return request
      .get(`/consultation/limit`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateConsultationLimit(apiParam) {
    return request
      .put(`/consultation/limit`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  }
};
export const consultant = {
  fetchScheduleList(apiParam) {
    return request
      .get(`/consultation/schedule/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchSchedule(apiParam) {
    return request
      .get(`/consultation/schedule${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultationContent(apiParam) {
    return request
      .get(`/consultation/content${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  updateConsultationContent(apiParam) {
    return request
      .put(`/consultation/content`, apiParam, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  },
  fetchConsultationContentList(apiParam) {
    return request
      .get(`/consultation/content/list${apiParam}`, {
        headers: {
          Authorization: "Token " + localStorage.getItem("token")
        }
      })
      .then(({ data }) => data);
  }
};
