<template>
  <div id="app">
    <router-view v-if="view.isPrepared" :key="$route.fullPath" />
    <etc-popup v-if="checkEtcPopup" :popupSet="popupSet" />
  </div>
</template>
<script>
import errorParser from "@/utils/error-parser";
import EtcPopup from "@/components/modal/EtcPopup.vue";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      view: {
        popupSet: {},
        versionOption: null,
        isPrepared: true
      }
    };
  },
  created() {
    window.callbackDeviceInfo = this.callbackDeviceInfo;
    console.log('TESTB', window.callbackDeviceInfo);
    // window.callbackAutoLogin = this.callbackAutoLogin;
    // document.location.href = "samkim://getDeviceInfo";
  },
  methods: {
    ...mapMutations("auth", ["SET_FCMTOKEN", "SET_DEVICEID", "SET_APPVERSION"]),
    // ...mapMutations("basic", ["SET_ETC_POPUP"]),
    // ...mapActions("auth", ["LOGIN_AUTO"]),
    ...mapActions("util", ["FETCH_APP_VERSION"]),

    async callbackDeviceInfo(getDeviceData) {
      console.log("콜백디바이스 인포 진입");
      if (getDeviceData !== "") {
        console.log("AAA")
        const deviceDataSet = JSON.parse(getDeviceData);
        const fcmToken = deviceDataSet.fcmToken;
        const deviceId = deviceDataSet.deviceId;
        const appVersion = deviceDataSet.appVersion;
        //const isAutoLogin = deviceDataSet.isAutoLogin;
        console.log("=== 콜백디바이스인포에서 가져온 CallbackDeviceInfo 정보 ===");
        console.log(" fcmToken :", fcmToken);
        console.log(" deviceId :", deviceId);
        console.log(" appVersion :", appVersion);
        //console.log(" isAutoLogin :", isAutoLogin);
        this.SET_FCMTOKEN(fcmToken);
        this.SET_DEVICEID(deviceId);
        this.SET_APPVERSION(appVersion);
        console.log("콜벡디바이스인포 저장됨");
        // if (isAutoLogin) {
        //   console.log("오토로그인 로직, view렌더 실행");
        //   this.checkAuto();
        // } else {
          this.versionCheck();
        // }
      } else {
        console.log("콜백디바이스 인포에서 에러남");
        // 콜백디바이스 인포 에러일떄를 정리하면 좋을 듯
      }
    },
    checkAuto() {
      // document.location.href = "samkim://autoLogin";
    },
    async callbackAutoLogin(getAutoLogin) {
      // /** 보여주기 처리 */
      // console.log("콜백 오토로그인 진입");
      // console.log("콜백오토로그인의 값", getAutoLogin);
      // if (getAutoLogin !== undefined) {
      //   const deviceDataSet = JSON.parse(getAutoLogin);
      //   const deviceId = deviceDataSet.deviceId;
      //   const fcmToken = deviceDataSet.fcmToken;
      //   const sessionToken = deviceDataSet.sessionToken;
      //   localStorage.setItem("token", sessionToken);
      //   try {
      //     console.log("오토로그인 API 시작점 진입");
      //     console.log("오토로그인 - deviceId :", deviceId);
      //     console.log("오토로그인 - fcmToken :", fcmToken);
      //     console.log("헤더토큰 검증 - ", sessionToken);
      //     const payload = {
      //       deviceId: deviceId,
      //       fcmToken: fcmToken
      //     };
      //     await this.LOGIN_AUTO(payload).then(data => {
      //       console.log("로그인 후 발급받은 토큰", data.response.token);
      //       localStorage.setItem("token", data.response.token);
      //       localStorage.setItem("userId", data.response.id);
      //       localStorage.setItem("userName", data.response.userName);
      //       const url = "samkim://refreshSessionToken?sessionToken=" + data.response.token;
      //       document.location.href = url;
      //       this.versionCheck();
      //     });
      //   } catch (error) {
      //     localStorage.removeItem("token");
      //     console.log("오토로그인 API 실패:");
      //     console.log("오토로그인 error: ", errorParser.parse(error));
      //     this.view.isPrepared = true;
      //   }
      // } else {
      //   this.view.isPrepared = true;
      // }
    },
    async versionCheck() {
      console.log("버전 로직 진입");
      try {
        await this.FETCH_APP_VERSION().then(data => {
          this.view.isPrepared = true;
          console.log("버전의 data :", data.response.option);
          if (Number(data.response.option) === 0) {
            alert("현재 버전이 최신버전입니다. - 테스트 -");
          } else if (Number(data.response.option) === 1) {
            alert("업데이트 권고사항입니다. - 테스트 -");
          } else if (Number(data.response.option) === 2) {
            alert("업데이트 강제사항입니다. - 테스트 -");
          }

          /*
          if (data.response.option === "0") {
            this.view.versionOption = 0;
          } else if (data.response.option === "1") {
            this.popupSet.popType = "warn";
            this.popupSet.popImg = "success.svg";
            this.popupSet.content =
              "업데이트 버전이 있습니다. 확인을 누르면 업데이트 하실 수 있습니다.";
            this.popupSet.cancelBtnText = "취소";
            this.popupSet.nextLink = data.response.url;
            this.popupSet.confirmBtnText = "확인";
            this.view.versionOption = 1;
          } else if (data.response.option === "2") {
            this.popupSet.popType = "warn";
            this.popupSet.popImg = "success.svg";
            this.popupSet.content = "최신 버전으로 업데이트 후 앱 사용이 가능합니다.";
            this.popupSet.cancelBtnText = null;
            this.popupSet.nextLink = data.response.url;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.destination = true;
            this.view.versionOption = 2;
          }
          */
        });
      } catch (error) {
        console.log("error :", errorParser.parse(error));
        this.view.isPrepared = true;
      }
    }
  },
  computed: {
    ...mapState("basic", ["checkEtcPopup"]),
    ...mapGetters("auth", ["GET_APPVERSION"])
  },
  components: {
    EtcPopup
  }
};
</script>


<style lang="scss">
@import "assets/css/init.css";
@import "assets/fonts/stylesheet.css";
</style>
