<template>
  <modal
    :destination="destination"
    :huge="huge"
    :medium="medium"
    :overlayRestrict="overlayRestrict"
    @forced-nextlink="goNextLink()"
  >
    <template v-slot:body>
      <div
        class="default-pop meaning-result-pop"
        v-if="popType === 'meaning-img'"
        :class="{ less: imgHide }"
      >
        <div class="close" @click="close()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <h3 class="title">결과물보기</h3>
        <div class="content">
          <p v-html="contentMutated"></p>

          <div class="img-wrap" v-if="!imgHide">
            <img
              :src="
                popImg.indexOf('blob:') === 0 || popImg.indexOf('http') === 0
                  ? popImg
                  : require(`@/assets/images/popup/${popImg}`)
              "
            />
          </div>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
        </div>
      </div>
      <div
        class="default-pop meaning-result-pop"
        v-if="popType === 'meaning-img2'"
        :class="{ less: imgHide }"
      >
        <div class="close" @click="close()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <h3 class="title">결과물보기</h3>
        <div class="content">
          <p v-html="contentMutated"></p>
          <div class="swiper-wrap" v-if="!imgHide">
            <swiper class="swiper" :options="swiperOption" ref="popSwiper">
              <swiper-slide v-for="(imgItem, imgIndex) in popImgArr.length" :key="imgIndex + 'A'">
                <div class="img-wrap">
                  <img :src="popImgArr[imgIndex]" />
                </div>
              </swiper-slide>
            </swiper>
            <div class="custom-swiper-button-prev" @click="prevSlide()">
              <img src="@assets/images/util/main_icon_carousel_arrow_left.svg" />
            </div>
            <div class="custom-swiper-button-next" @click="nextSlide()">
              <img src="@assets/images/util/main_icon_carousel_arrow_right.svg" />
            </div>
          </div>
          <!--
          <div class="img-wrap" v-if="!imgHide">
            <img
              :src="
                popImg.indexOf('blob:') === 0 || popImg.indexOf('http') === 0
                  ? popImg
                  : require(`@/assets/images/popup/${popImg}`)
              "
            />
          </div>
          -->
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
        </div>
      </div>
      <div class="share-pop" v-if="popType === 'share'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <div class="content">
          <!--
          <div class="img-wrap">
            <img src="@assets/images/util/x.svg" />
          </div>
          -->
          <div class="sample"></div>
        </div>
        <div class="btn-wrap">
          <button type="button" @click="close()">취소</button>
          <button class="activate" @click="share()">
            <img src="@assets/images/util/kakao.png" />공유하기
          </button>
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame tat" v-if="popType === 'write'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="textarea-wide">
          <textarea v-model="dataValue" @keyup.enter="write()" />
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" @click="close()" />
          <button class="active" v-text="confirmBtnText" type="button" @click="write()" />
        </div>
      </div>
      <div class="default-pop fram type" v-if="popType === 'type'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content-title">
          <p>상담유형</p>
        </div>
        <div class="content-body">
          <div class="left-btn-wrap" :class="{inActive : typeFlag === true}" @click="flagActive()">
            <p class="left-btn">음성</p>
          </div>
          <div class="right-btn-wrap" :class="{inActive : typeFlag2 === true}" @click="flagActive2()">
            <p class="right-btn">영상</p>
          </div>
        </div>
        <div class="btn-wrap" :class="{inActive : typeFlag === true || typeFlag2 === true}">
          <button v-text="confirmBtnText" type="button" @click="carrytypeflag()"/>
        </div>
      </div>
      <div class="default-pop frame resv" v-if="popType === 'resv'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img" v-if="!imgHide">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="resv-info">
          <div class="article">
            <p>상담 유형</p>
            <p class="bold" v-text="resvConts.type"></p>
          </div>
          <div class="article">
            <p>상담 날짜</p>
            <p class="bold" v-text="resvConts.date"></p>
          </div>
          <div class="article">
            <p>상담 시간</p>
            <p class="bold" v-text="resvConts.time"></p>
          </div>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="reservation()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame wish" v-if="popType === 'wish'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <h3 class="title">상담 희망 내용</h3>
        <div class="textarea-wrap">
          <textarea v-text="wishContent" :disabled="wishFix"></textarea>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame consultant" v-if="popType === 'setConsultant'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <h3 class="title">상담자 배정</h3>
        <!-- 공간 -->
        <div class="select-consultant">
          <div class="item">
            <div class="select-wrap">
              <v-select
                :options="consultantList"
                :searchable="false"
                v-model="choicedConsultant"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <div class="item">
            <button
              type="button"
              @click="store()"
              class="confirm"
              :class="{ inActivated: choicedConsultant === '선택' }"
              :disabled="choicedConsultant === '선택'"
            >
              저장
            </button>
          </div>
        </div>
      </div>
      <div class="default-pop frame training" v-if="popType === 'trainingAmount'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <h3 class="title">삼킴 훈련 횟수 설정</h3>
        <!-- 공간 -->
        <div class="choice-amount">
          <div class="item">
            <div class="select-wrap">
              <v-select
                :options="selectOpt.amount.option"
                :searchable="false"
                @input="selectAmount()"
                v-model="selectOpt.amount.value"
              ></v-select>
            </div>
            <p>번씩</p>
          </div>
          <div class="item">
            <div class="select-wrap">
              <v-select
                :options="selectOpt.set.option"
                :searchable="false"
                @input="selectSet()"
                v-model="selectOpt.set.value"
              ></v-select>
            </div>
            <p>세트</p>
          </div>
        </div>
        <div class="center-btn-wrap">
          <div class="item">
            <button type="button" @click="close()" class="cancel">취소</button>
          </div>
          <div class="item">
            <button
              type="button"
              @click="setTrainingAmount(false, selectOpt, selectedOrder)"
              class="confirm"
            >
              확인
            </button>
          </div>
        </div>
        <!-- <div class="content">
          <br />
          <br />
          <p v-html="contentMutated"></p>
        </div>
        <div class="center-btn-wrap">
          <div class="item">
            <button
              type="button"
              @click="setTrainingAmount(true, selectOpt, selectedOrder)"
              class="all"
            >
              전체적용
            </button>
          </div>
        </div> -->
      </div>
      <div class="default-pop frame trainingTime" v-if="popType === 'trainingTime'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <h3 class="title">삼킴 훈련 시간 설정</h3>
        <!-- 공간 -->
        <div class="choice-amount">
          <div class="item">
            <div class="select-wrap">
              <p>아침</p>
              <v-select
                :options="selectOpt.morning.option"
                :searchable="false"
                v-model="selectOpt.morning.value"
              ></v-select>
            </div>
          </div>
          <div class="item">
            <div class="select-wrap">
              <p>점심</p>
              <v-select
                :options="selectOpt.afternoon.option"
                :searchable="false"
                v-model="selectOpt.afternoon.value"
              ></v-select>
            </div>
          </div>
          <div class="item">
            <div class="select-wrap">
              <p>저녁</p>
              <v-select
                :options="selectOpt.night.option"
                :searchable="false"
                v-model="selectOpt.night.value"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="center-btn-wrap">
          <div class="item">
            <button type="button" @click="close()" class="cancel">취소</button>
          </div>
          <div class="item">
            <button
              type="button"
              @click="setTrainingTime(false, selectOpt, selectedOrder)"
              class="confirm"
            >
              확인
            </button>
          </div>
        </div>
        <!-- <div class="content">
          <br />
          <br />
          <p v-html="contentMutated"></p>
        </div>
        <div class="center-btn-wrap">
          <div class="item">
            <button
              type="button"
              @click="setTrainingTime(true, selectOpt, selectedOrder)"
              class="all"
            >
              전체적용
            </button>
          </div>
        </div> -->
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import vSelect from 'vue-select';
import Modal from './Modal.vue';

export default {
  props: {
    popupSet: {
      overlayRestrict: {
        type: Boolean,
        default: false,
      },
      wishFix: {
        type: Boolean,
        default: false,
      },
      content: {
        type: String,
      },
      imgHide: {
        type: Boolean,
        default: false,
      },
      nextLink: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      returnList: {
        type: Boolean,
        default: false,
      },
      popType: {
        type: String,
        default: 'warn',
      },
      popImg: {
        type: String,
        default: 'warning.svg',
      },
      popImgArr: {
        type: Array,
      },
      destination: {
        type: Boolean,
      },
      huge: {
        type: Boolean,
        default: false,
      },
      medium: {
        type: Boolean,
        default: false,
      },
      param: {
        type: String,
      },
      resvConts: {
        type: Object,
      },
      wishContent: {
        type: String,
      },
      isApiCall: {
        type: Boolean,
      },
      selectedOrder: {
        type: Number,
      },
      selectOpt: {
        type: Object,
      },
      consultOpt: {
        type: Array,
      },
      dataValue: {
        type: String,
      },
    },
  },
  components: {
    Modal,
    vSelect,
  },
  data() {
    return {
      popType: this.popupSet.popType || 'warn',
      popImgArr: this.popupSet.popImgArr,
      popImg: this.popupSet.popImg || 'warning.s vg',
      content: this.popupSet.content,
      nextLink: this.popupSet.nextLink,
      cancelBtnText: this.popupSet.cancelBtnText,
      confirmBtnText: this.popupSet.confirmBtnText,
      destination: this.popupSet.destination,
      huge: this.popupSet.huge,
      medium: this.popupSet.medium,
      param: this.popupSet.param,
      imgHide: this.popupSet.imgHide,
      resvConts: this.popupSet.resvConts,
      isApiCall: this.popupSet.isApiCall,
      wishContent: this.popupSet.wishContent,
      selectedOrder: this.popupSet.selectedOrder,
      selectOpt: this.popupSet.selectOpt,
      consultOpt: this.popupSet.consultOpt,
      consultantList: '',
      choicedConsultant: '',
      dataValue: this.popupSet.dataValue,
      returnList: this.popupSet.returnList,
      wishFix: this.popupSet.wishFix,
      overlayRestrict: this.popupSet.overlayRestrict,
      typeFlag: this.popupSet.typeFlag,
      typeFlag2: this.popupSet.typeFlag2,
      swiperOption: {
        slidesPerView: 1,
        setWrapperSize: true,
        allowTouchMove: false,
        navigation: {
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev',
        },
      },
    };
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf('\n') !== 1) {
        return this.content.split('\n').join('<br />');
      }
      return this.content;
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    nextSlide() {
      this.$refs.popSwiper.$swiper.slideNext();
    },
    prevSlide() {
      this.$refs.popSwiper.$swiper.slidePrev();
    },
    flagActive() {
      console.log('1 들어옴');
      this.typeFlag = true;
      this.typeFlag2 = false;
      this.dataValue = 0;
    },
    flagActive2() {
      console.log('2 들어옴');
      this.typeFlag = false;
      this.typeFlag2 = true;
      this.dataValue = 1;
    },
    carrytypeflag() {
      this.$emit('transtype', this.dataValue);
      this.SET_POPUP(false);
    },
    setConsultantArray() {
      /** consultantList choicedConsultant  */
      this.consultantList = this.consultOpt.consultantList;
      if (this.consultOpt.choicedConsultant !== '') {
        this.choicedConsultant = this.consultOpt.choicedConsultant;
      } else {
        this.choicedConsultant = '선택';
      }
    },
    store() {
      this.$emit('choiced-consultant', this.choicedConsultant);
      this.SET_POPUP(false);
    },
    selectAmount() {},
    selectSet() {},
    setTrainingAmount(isMultiple, array, order) {
      this.$emit('training-amount-proceed', isMultiple, array, order);
      this.SET_POPUP(false);
    },
    selectTrainingTime() {},
    setTrainingTime(isMultiple, array, order) {
      this.$emit('training-time-proceed', isMultiple, array, order);
      this.SET_POPUP(false);
    },
    reservation() {
      this.$emit('resv-call');
      this.SET_POPUP(false);
    },
    share() {
      console.log('api - param', this.param);
      alert('준비중');
    },
    goNextLink() {
      this.$router.push(this.nextLink);
    },
    write() {
      this.$emit('transed-pop-data', this.dataValue);
      this.SET_POPUP(false);
    },
    type() {
      this.$emit('transed-pop-data', this.dataValue);
      this.SET_POPUP(false);
    },
    action() {
      this.SET_POPUP(false);
      if (this.isApiCall) {
        this.$emit('api-called');
      } else if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
    },
    close() {
      if (this.destination && this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      } else if (this.returnList !== undefined && this.returnList) {
        this.$router.go(-1);
      }
      this.SET_POPUP(false);
    },
  },
  created() {
    console.log('가져온 팝업아이템', this.popupSet);
    if (this.consultOpt !== undefined) {
      this.setConsultantArray();
    }
    console.log('popImgArr 0', this.popImgArr[0]);
    console.log('popImgArr 1', this.popImgArr[1]);
  },
};
</script>

<style lang="scss" scoped>
.medium {
  .modal-wrapper {
    .modal-container {
      .content .img-wrap {
        max-width: 590px;
        margin: 0 auto 60px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.frame {
  padding: 30px;
  min-height: 300px;
}
img {
  max-height: 600px;
  max-width: 1200px;
  height: auto;
  width: auto;
}
h3.title {
  @include initfontKor(
    $font-size: 26px,
    $line-height: 36px,
    $font-family: "AppleSDGothicNeoB00",
    $color: #292a2b,
    $letter-spacing: -0.52px,
    $text-align: center
  );
  margin: 0 0 12px 0;
}
.share-pop {
  width: inherit;
  .content {
    padding: 20px;
    margin: 0 0 60px 0;
    .sample {
      width: 100%;
      margin: 0 auto;
      min-height: 524px;
      background: #676767;
    }
  }
  .close {
    position: absolute;
    top: -5px;
    right: -55px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &.activate {
        background: #fd8b11;
        img {
          max-width: 32px;
          height: auto;
          margin: 0 20px 0 0;
        }
      }
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
}
.default-pop {
  width: inherit;
  &.type {
    margin: 10px auto 20% auto;
    display: flex;
    flex-direction: column;
    .content-title {
      margin: 5px auto 5px 5%;
      p {
        font-family: "AppleSDGothicNeoB00";
        color: #292a2b;
        text-align: left;
        line-height: 36px;
        font-size: 26px;
        letter-spacing: -0.52px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
      }
    }
    .content-body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20%;
      padding: 20px 0 20px 0;
      margin: auto;
      width: 60%;
      height: 10%;
      .left-btn-wrap,
      .right-btn-wrap {
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #292a2b;
        height: 40px;

        &.inActive {
          background: #fd8b11;
          border: 0px;
          p {
            color: #fff;
          }
        }

        p {
          text-align: center;
          line-height: 2;
          font-family: "AppleSDGothicNeoM00";
          font-weight: normal;
          font-size: 20px;
          color: #292a2b;
        }
      }
    }
    .btn-wrap {
      button {
        width: 100%;
        border-radius: 6px;
        border: 0;
        padding: 0;
        cursor: pointer;
        font-family: "AppleSDGothicNeoM00";
        color: #292a2b;
        text-align: center;
        line-height: 40px;
        font-size: 21px;
        letter-spacing: -0.48px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
      }
      &.inActive {
        background: #fd8b11;
        button {
          background: #fd8b11;
          color: #fff;
        }
      }
    }
  }
  &.tat {
    .textarea-wide {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }

  &.meaning-result-pop {
    &.less {
      min-height: 265px;
    }
    padding: 40px 0;
    .img-wrap {
      display: flex;
      justify-content: center;
      margin: 0 0 60px 0;
    }
  }
  &.consultant {
    min-height: inherit;
    h3.title {
      margin: 0 0 25px 0;
      text-align: left;
    }
    .select-consultant {
      display: flex;
      margin: 0 0 65px 0;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        .select-wrap {
          width: 300px;
        }
        p {
          margin: 0 0 0 10px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
      }
    }
    .btn-wrap {
      display: flex;
      position: relative;
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        button {
          width: 120px;
          border-radius: 6px;
          border: 0;
          padding: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.inActivated {
            background: #f3f3f3 !important;
            color: #292a2b !important;
          }
          &.cancel {
            background: #ff0000;
            color: #fff;
          }
          &.all {
            background: #f36900;
            color: #fff;
          }
          &.confirm {
            background: #fd8b11;
            color: #fff;
          }
        }
      }
    }
  }
  &.training {
    h3.title {
      margin: 0 0 25px 0;
    }
    .choice-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 65px 0;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        .select-wrap {
          width: 120px;
        }
        p {
          margin: 0 0 0 10px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
      }
    }
    .center-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        button {
          width: 120px;
          border-radius: 6px;
          border: 0;
          padding: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.cancel {
            background: #ff0000;
            color: #fff;
          }
          &.all {
            background: #f36900;
            color: #fff;
          }
          &.confirm {
            background: #fd8b11;
            color: #fff;
          }
        }
      }
    }
  }
  &.trainingTime {
    h3.title {
      margin: 0 0 25px 0;
    }
    .choice-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 65px 0;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        .select-wrap {
          width: 120px;
        }
        p {
          margin: 0 0 0 10px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
      }
    }
    .center-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        margin: 0 0 0 20px;
        &:first-of-type {
          margin: 0;
        }
        button {
          width: 120px;
          border-radius: 6px;
          border: 0;
          padding: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 21px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.cancel {
            background: #ff0000;
            color: #fff;
          }
          &.all {
            background: #f36900;
            color: #fff;
          }
          &.confirm {
            background: #fd8b11;
            color: #fff;
          }
        }
      }
    }
  }
  &.wish {
    padding: 25px 40px;
    h3.title {
    }
    .textarea-wrap {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }
  &.resv {
    padding: 30px 65px;
    .pop-img {
      margin: 0 auto 45px auto;
    }
    .content {
      margin: 0 0 20px 0;
    }
    .resv-info {
      margin: 0 0 80px 0;
      .article {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(49, 87, 84, 0.2);
        &:last-of-type {
          border-bottom: 0;
        }
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.52px,
            $text-align: left
          );
          &.bold {
            font-family: "AppleSDGothicNeoB00";
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .pop-img {
    max-width: 105px;
    margin: 0 auto 32px auto;
    display: flex;
    justify-content: center;
    img {
    }
  }
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    &.alone {
      button {
        width: 100%;
        background: #fd8b11;
        color: #fff;
      }
    }
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
}
</style>
