<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container mini-tab">
        <tabs @tab-selected="reloadList">
          <tab :tabData="tab[0]" v-if="view.isVisible">
            <tab-list-posture
              :listType="0"
              v-if="viewTab[0].flag"
              :result="listCondition[0].result"
              @call-result-pop="resultPop"
              @call-download="download"
            ></tab-list-posture>
          </tab>
          <tab :tabData="tab[1]" v-if="view.isVisible">
            <tab-list-posture
              :listType="1"
              v-if="viewTab[1].flag"
              :result="listCondition[1].result"
              @call-result-pop="resultPop"
              @call-download="download"
            ></tab-list-posture>
          </tab>
        </tabs>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import TabListPosture from "@/components/TabListPosture.vue";
import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      listCondition: [
        {
          result: []
        },
        {
          result: []
        }
      ],
      tree: {
        title: "자세법과 식이법",
        detail: "자세법과 식이법 리스트",
        detail2: "결과보기"
      },
      tab: [
        {
          title: "자세법"
        },
        {
          title: "식이법"
        }
      ],
      viewTab: [
        {
          flag: false
        },
        {
          flag: false
        }
      ],
      view: {
        isVisible: false
      },
      selectedTabIndex: 0
    };
  },
  created() {
    // localStorage.setItem(
    //   "token",
    //   "c778815b739866cc2c4d884f1f73e4639847babca9e928635e0f6a8a9d946e7b"
    // );
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions("activity", ["FETCH_PF_DETAIL", "FETCH_PF_RESULT", "FETCH_PF_RESULT_IMAGE"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    download(info) {
      /** 정보로 API 조회 */
      console.log("info :", info);
      alert("다운로드 준비중");
    },
    resultPop(info) {
      /** 정보로 API 조회 */
      console.log("info :", info);
      let showType = this.selectedTabIndex == 0 ? "position" : "feeding";
      this.FETCH_PF_RESULT(`?id=${info.id}&show_type=${showType}`).then(async data => {
        console.log("content : ", data.response.content);
        console.log("list    : ", data.response.list);

        let content = data.response.content;

        let imageList = [];
        for (let i = 0; i < data.response.list.length; i++) {
          let postData = {
            id: data.response.list[i],
            show_type: showType
          };

          await this.FETCH_PF_RESULT_IMAGE(postData).then(data => {
            imageList.push(window.URL.createObjectURL(data));
            console.log("imageList :", imageList);
          });
        }

        if (imageList.length == 0) {
          console.log("결과물 없어");
          const getList = {
            content: "결과 이미지가 존재하지 않습니다."
          };
          this.SET_POPUP(true);
          this.popupSet.imgHide = true;
          this.popupSet.popType = "meaning-img";
          this.popupSet.content = getList.content;
          this.popupSet.confirmBtnText = null;
          this.popupSet.cancelBtnText = "닫기";
          return;
        }

        // TODO : 결과물 imageList 여러개의 경우 화면에 어떻게 보여줄지?
        console.log("결과물 보기 팝업");
        const getList = {
          content: content,
          imgSrc: imageList[0]
        };
        this.SET_POPUP(true);
        this.popupSet.medium = true;

        this.popupSet.popType = "meaning-img2";
        this.popupSet.popImgArr = imageList;
        /**
        this.popupSet.popImg = getList.imgSrc;
         */
        this.popupSet.content = data.response.content;
        this.popupSet.confirmBtnText = null;
        this.popupSet.cancelBtnText = "닫기";
      });
    },
    reloadList(order) {
      console.log("현재 몇번째 순번 탭? :", order);
      this.fetchData(order);
    },
    async fetchData(order) {
      this.selectedTabIndex = order;
      let showType = order == 0 ? "position" : "feeding";
      await this.FETCH_PF_DETAIL(`?id=${this.$route.params.code}&show_type=${showType}`).then(
        data => {
          console.log(data.response.result_list);
          this.listCondition[order].result = data.response.result_list;
          this.view.isVisible = true;
          this.viewTab[order].flag = true;
        }
      );
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"])
  },
  components: {
    SideMenu,
    Notification,
    TabListPosture,
    DefaultPopup,
    Tabs,
    Tab
  }
};
</script>
<style lang="scss" scoped>
.tabs__header {
  margin: 100px;
}
</style>
