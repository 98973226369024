<template>
  <div class="login-box">
    <div class="login">
      <div class="logo-area">
        <div class="img-wrap">
          <img src="@assets/images/util/logo-samkim.png" />
        </div>
        <h2>삼킴 건강코치</h2>
        <br/>
        <h3>관리자 로그인</h3>
      </div>
      <div class="content-wrap">
        <div class="input-area">
          <div class="item">
            <div class="input-wrap">
              <input type="text" placeholder="아이디" v-model="login.id.value" @input="checkId()" />
              <p class="warn" v-text="login.id.errorTxt" v-if="login.id.hasError" />
            </div>
          </div>
          <div class="item">
            <div class="input-wrap">
              <input type="password" placeholder="비밀번호" v-model="login.password.value" @keyup.enter="loginAction()" @input="checkPw()" />
              <p class="warn" v-text="login.password.errorTxt" v-if="login.password.hasError" />
            </div>
          </div>
        </div>
        <div class="btn-wrap" :class="{ active: login.id.flag && login.password.flag }">
          <button type="button" @click="loginAction()">로그인</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      login: {
        id: {
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '아이디를 점검해주세요',
        },
        password: {
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비밀번호를 점검해주세요',
        },
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null && localStorage.getItem('token') != '') { this.$router.push('/'); }
  },
  methods: {
    ...mapActions('util', ['FETCH_LOGIN']),
    ...mapMutations('basic', ['SET_POPUP', 'SET_SEARCH_POPUP']),
    checkId() {
      console.log('동작해 id');
      this.login.id.flag = false;
      const id = this.login.id.value.replace(/\s/gi, '');
      if (id === '') {
        this.login.id.flag = false;
        this.login.id.hasError = true;
        this.login.id.errorTxt = '아이디를 입력해주세요.';
        return false;
      }
      const isId = /^[a-zA-Z][a-zA-Z0-9]{4,24}$/;
      if (!isId.test(id)) {
        this.login.id.errorTxt = '한글 및 영문만 입력해주세요.';
        this.login.id.flag = false;
        this.login.id.hasError = true;
        return false;
      }
      this.login.id.flag = true;
      this.login.id.hasError = false;
      this.login.id.errorTxt = '';
      return true;
    },
    checkPw() {
      this.login.password.flag = false;
      const password = this.login.password.value.replace(/\s/gi, '');
      if (password === '') {
        this.login.password.flag = false;
        this.login.password.hasError = true;
        this.login.password.errorTxt = '비밀번호를 입력해주세요.';
        return false;
      }
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
      if (!isPw.test(password)) {
        this.login.password.errorTxt = '영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.';
        this.login.password.flag = false;
        this.login.password.hasError = true;
        return false;
      }
      this.login.password.flag = true;
      this.login.password.hasError = false;
      this.login.password.errorTxt = '';
      return true;
    },
    checkFlag() {
      if (!this.login.id.flag || !this.login.password.flag) {
        if (!this.login.id.flag) {
          this.login.id.hasError = true;
        } else if (!this.login.password.flag) {
          this.login.id.hasError = true;
        }
        return false;
      }
      return true;
    },
    async loginAction() {
      console.log('**loginAction**');

      if (this.checkFlag()) {
        const id = this.login.id.value.replace(/\s/gi, '');
        const password = this.login.password.value.replace(/\s/gi, '');
        const userData = {
          id,
          password,
        };

        await this.FETCH_LOGIN(userData).then((data) => {
          if (data.result) {
            console.log('성공');
            localStorage.setItem('token', data.response.token);
            localStorage.setItem('userId', data.response.id);
            localStorage.setItem('userName', data.response.name);

            this.SET_POPUP(true);
            this.popupSet.popType = 'warn';
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = '로그인이 되었습니다.';
            this.popupSet.cancelBtnText = null;
            this.popupSet.nextLink = '/';
            this.popupSet.confirmBtnText = '확인';
            this.popupSet.destination = true;
          } else {
            console.log('실패');
            this.SET_POPUP(true);
            this.popupSet.popType = 'warn';
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = '아이디 또는 비밀번호가 잘못되었습니다!';
            this.popupSet.cancelBtnText = '확인';
            this.popupSet.confirmBtnText = null;
          }
        });
      }
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkSearchPopup']),
  },
  components: {
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.login {
  position: absolute;
  top: calc(50% - 65px);
  left: 50%;
  transform: translate(-50%, -50%);
  .logo-area {
    width: 430px;
    margin: 0 0 40px 0;
    .img-wrap {
      display: flex;
      justify-content: center;
      margin: 0 0 25px 0;
    }
    h2 {
      margin: 10px 0 0 0;
      @include initfontKor(
        $font-size: 50px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
    }
    h3 {
      margin: 10px 0 0 0;
      @include initfontKor(
        $font-size: 40px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
    }
  }
  .content-wrap {
    width: 430px;
    .input-area {
      margin: 0 0 40px 0;
      .item {
        margin: 40px 0 0 0;
        &:first-of-type {
          margin: 0;
        }
        .input-wrap {
          width: inherit;
          position: relative;
          input {
            border: 1px solid #acb3c9;
            border-radius: 8.5px;
            width: 100%;
            padding: 0 12px;
            @include initfontKor(
              $font-size: 26px,
              $line-height: 60px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
          p.warn {
            position: absolute;
            bottom: -30px;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 26px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #ff0000,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
        }
      }
    }
    .btn-wrap {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8.5px;
      overflow: hidden;
      &.active {
        background: #fd8b11;
        button {
          color: #fff;
        }
      }
      background: #f3f3f3;
      button {
        border: 0;
        padding: 0;
        height: 100%;
        width: inherit;
        background: transparent;
        cursor: pointer;
        @include initfontKor(
          $font-size: 32px,
          $line-height: 42px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #292a2b,
          $letter-spacing: -0.6px
        );
      }
    }
  }
}
</style>
