<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="meaning-result">
          <div class="member-info-set">
            <div class="header">
              <div class="item">
                <p>회원명</p>
              </div>
              <div class="item">
                <p>탐색일자</p>
              </div>
            </div>
            <div class="content">
              <div class="item">
                <p v-text="member.userName" />
              </div>
              <div class="item">
                <p v-text="member.searchDate" />
              </div>
            </div>
          </div>
        </div>
        <div class="meaning-list">
          <div class="header">
            <div class="item">
              <p>일차</p>
            </div>
            <div class="item">
              <p>탐색일</p>
            </div>
            <div class="item">
              <p>진행률</p>
            </div>
            <div class="item">
              <p>결과물 보기</p>
            </div>
          </div>
          <div class="content">
            <div class="item" v-for="(resultItem, index) in result.list" :key="index + 'B'">
              <div class="p-wrap">
                <p v-text="resultItem.day" />
              </div>
              <div class="p-wrap">
                <p v-text="transDate(resultItem.updateAt)" />
              </div>
              <div class="p-wrap">
                <p v-text="resultItem.progress + '%'" />
              </div>
              <div class="p-wrap">
                <p
                  :class="{ status: resultItem.isResult }"
                  v-text="resultItem.isResult ? '상세보기' : '-'"
                  @click="goDetail(result.id, resultItem.day, resultItem.isResult)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "삶의 의미 탐색",
        detail: "삶의 의미 탐색 리스트",
        detail2: "일차별 상세정보"
      },
      member: [],
      result: {}
    };
  },
  created() {
    /**
     * MeaningDetail: 리스트의 정보
     */

    /** this.getMember(JSON.parse(this.$route.query.MeaningDetail));  */

    window.scrollTo(0, 0);
    this.getData(JSON.parse(this.$route.query.MeaningDetail));
  },
  methods: {
    ...mapActions("activity", ["FETCH_LIFE_DETAIL", "FETCH_LIFE_RESULT"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    goDetail(id, day, isResult) {
      if (!isResult) return;

      console.log("day", day);
      if (day === 1 || day === 40) {
        console.log("설문내용 확인 페이지");
        const param = {
          id: id,
          day: day
        };
        this.$router.push({
          name: "MeaningExplain",
          query: { MeaningExplain: param }
        });
      } else {
        console.log("결과물 보기 팝업");
        /** id, day 값으로 API 만들고 실행 */
        this.FETCH_LIFE_RESULT(`?id=${id}&day=${day}`).then(data => {
          console.log("텍스트,이미지 결과 data :", data);

          let convertData = {
            text: data.response.text,
            image: []
          };

          for (let i = 0; i < data.response.image.length; i++)
            convertData.image.push(
              `${process.env.VUE_APP_MAIN_URL}${data.response.image[i].image}`
            );

          console.log("convert data :", convertData);
          this.SET_POPUP(true);
          this.popupSet.medium = true;
          this.popupSet.popType = "meaning-img";
          this.popupSet.popImg = convertData.image.length > 0 ? convertData.image[0] : "";
          this.popupSet.content = convertData.text;
          this.popupSet.confirmBtnText = null;
          this.popupSet.cancelBtnText = "닫기";
        });
      }
    },
    getMember(info) {
      console.log("info : ", info);
      /** 리스트의 정보를 가지고옴 */
    },
    async getData(info) {
      console.log("info: ", info);

      const getList = {
        searchDate: info.updateAt.length > 10 ? info.updateAt.substring(0, 10) : "",
        userName: info.userName
      };
      this.member = getList;

      const param = "?id=" + info.memberId;
      try {
        await this.FETCH_LIFE_DETAIL(param).then(data => {
          console.log("data: ", data.response);
          this.result = data.response;
        });
      } catch (error) {
        console.log("error:", error);
      }
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    transDate() {
      return date => {
        console.log("date :", date);
        return this.$moment(date).format("YYYY-MM-DD");
      };
    }
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup
  }
};
</script>
<style lang="scss" scoped>
.member-info-set {
  margin: 0 0 105px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: repeat(auto-fill, 250px);
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: repeat(auto-fill, 250px);
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.meaning-list {
  margin: 0 0 65px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    .item {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .p-wrap {
        outline: 1px solid #acb3c9;
        padding: 4px 0;
        p {
          @include initfontKor(
            $font-size: 16px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          &.status {
            width: 105px;
            background: #292a2b;
            color: #fff;
            margin: 0 auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
