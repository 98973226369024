import Vue from "vue";
import vueMoment from "vue-moment";
import App from "./App.vue";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import "swiper/swiper-bundle.css";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from "swiper/core";
import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import router from "./router";
import VueSanitize from "vue-sanitize";
import { store } from "./store/index";
import CKEditor from "@ckeditor/ckeditor5-vue2";

Vue.config.productionTip = false;
// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay]);

// datepicker 옵션
const datepickerOptions = {
  sundayFirst: true,
  dateLabelFormat: "YYYY, MM D, dddd",
  days: ["월요일", "화요일", "수요일", "목요일", "금요일", "토요일", "일요일"],
  daysShort: ["월", "화", "수", "목", "금", "토", "일"],
  monthNames: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월"
  ],
  colors: {
    selected: "#315754",
    inRange: "rgb(255, 219, 219)",
    text: "#403f3f",
    selectedText: "#403f3f",
    disabled: "transparent",
    inRangeBorder: "#e4e7e7",
    hoveredInRange: "rgb(255, 219, 219)"
  }
};

Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(vSelect);
Vue.config.productionTip = false;
Vue.use(vueMoment);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(VueSanitize);
Vue.use(CKEditor);
const vm = new Vue({
  router,
  store,
  vueMoment,
  datepickerOptions,
  render: h => h(App)
}).$mount("#app");
window.app = vm;
