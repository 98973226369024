<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="voice-record">
          <div class="member-info-set">
            <div class="header">
              <div class="item" v-for="(header, index) in member" :key="index + 'A'">
                <p v-text="header.questionText" />
              </div>
            </div>
            <div class="content">
              <div class="item" v-for="(value, index) in member" :key="index + 'B'">
                <p v-text="value.answerResult" v-if="index === 0" />
                <div class="select-wrap" v-else>
                  <v-select
                    :searchable="false"
                    :options="value.answerResultOpt"
                    @input="dayPick(value.answerResult)"
                    v-model="value.answerResult"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="record-section" v-if="view.voicePrepared">
            <section
              v-for="(keyItem, keyIndex) in Object.keys(result).length"
              :key="keyIndex"
              :class="{
                hide:
                  (keyIndex === 0 && view.firstHide) ||
                  (keyIndex === 1 && view.secondHide) ||
                  (keyIndex === 2 && view.thirdHide)
              }"
            >
              <h3 class="title" v-text="transTxt(Object.keys(result)[keyIndex])" />
              <div
                class="record-wrap"
                v-for="(valueItem, valueIndex) in result[Object.keys(result)[keyIndex]].length"
                :key="valueIndex"
              >
                <div
                  class="second-wrap"
                  v-for="(secondParent, secondParentIndex) in result[Object.keys(result)[keyIndex]][
                    valueIndex
                  ].list[0]"
                  :key="secondParentIndex"
                >
                  <div class="time-title" v-if="secondParentIndex === 'second'">
                    <span class="left-direction">횟수</span>
                    <span class="right-direction">시간</span>
                  </div>
                  <div class="second-box" v-if="secondParentIndex === 'second'">
                    <!--
                    {{
                      result[Object.keys(result)[keyIndex]][valueIndex].list[0][secondParentIndex]
                    }}
                    -->
                    <div
                      class="second"
                      v-for="(secondBoxItem, secondBoxIndex) in result[
                        Object.keys(result)[keyIndex]
                      ][valueIndex].list[0][secondParentIndex].length"
                      :key="secondBoxIndex"
                    >
                      <span
                        v-text="
                          result[Object.keys(result)[keyIndex]][valueIndex].list[0][
                            secondParentIndex
                          ][secondBoxIndex]
                        "
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="record"
                  v-for="(recordItem, recordIndex) in result[Object.keys(result)[keyIndex]][
                    valueIndex
                  ].list.length"
                  :key="recordIndex"
                  :class="{ first: recordIndex === 0 }"
                >
                  <div class="order-area">
                    <div class="number-wrap">
                      <div
                        class="number"
                        @click="linkAudio(Object.keys(result)[keyIndex], valueIndex, recordIndex)"
                      >
                        <div class="img-wrap">
                          <img src="@/assets/images/util/icon_sound.svg" />
                        </div>
                        <span
                          v-text="
                            result[Object.keys(result)[keyIndex]][valueIndex].list[recordIndex]
                              .number
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="detail-area">
                    <div class="hertz-wrap">
                      <div
                        class="hertz"
                        v-for="(hertzItem, hertzIndex) in result[Object.keys(result)[keyIndex]][
                          valueIndex
                        ].list[recordIndex].hertz.length"
                        :key="hertzIndex"
                      >
                        <span
                          v-text="
                            result[Object.keys(result)[keyIndex]][valueIndex].list[recordIndex]
                              .hertz[hertzIndex]
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!--
          <div class="record-area">
            <div
              class="record-set"
              v-for="(parentItem, parentIndex) in Object.entries(result)"
              :key="parentIndex + 'C'"
            >
              <h5 class="title" v-text="transTitle(parentIndex)" />
              <div class="record-box">
                <div
                  class="record"
                  v-for="(parentInnerItem, parentInnerIndex) in Object.entries(
                    parentItem[1][0].list.slice(0, 10)
                  )"
                  :key="parentInnerIndex + 'D'"
                >
                  <h6 class="header" v-text="parentInnerIndex + 1" />
                  <div class="content">
                    <p v-text="parentInnerItem[1].number" />
                  </div>
                </div>
              </div>
              <div class="record-box">
                <div
                  class="record"
                  v-for="(parentInnerItem, parentInnerIndex) in Object.entries(
                    parentItem[1][0].list.slice(10, parentItem[1][0].list.length)
                  )"
                  :key="parentInnerIndex + 'E'"
                >
                  <h6 class="header" v-text="parentInnerIndex + 1" />
                  <div class="content">
                    <p v-text="parentInnerItem[1].number" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "삼킴 훈련",
        detail: "삼킴 훈련 리스트",
        detail2: "훈련별 상세정보",
        detail3: "음도 높이기"
      },
      member: [],
      result: {},
      view: {
        voicePrepared: false,
        firstHide: false,
        secondHide: false,
        thirdHide: false
      }
    };
  },
  created() {
    console.log(this.$route.query.userId);
    console.log(this.$route.query.date);

    window.scrollTo(0, 0);
    this.getMember();
    this.getData();
  },
  methods: {
    ...mapActions("training", ["FETCH_TRAINING_HERTZ", "FETCH_TRAINING_DATE"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    dayPick(value) {
      console.log("daypickValue :", value);
      this.reloadData(value);
    },
    linkAudio(where, parentIndex, index) {
      let audioUrl;
      if (where === "morning") {
        audioUrl = this.result.morning[parentIndex].list[index].audio;
      } else if (where === "afternoon") {
        audioUrl = this.result.afternoon[parentIndex].list[index].audio;
      } else if (where === " evening") {
        audioUrl = this.result.evening[parentIndex].list[index].audio;
      }

      if (audioUrl === null || audioUrl === undefined) {
        this.SET_POPUP(true);
        this.popupSet = {};
        this.popupSet.popType = "warn";
        this.popupSet.popImg = "success.svg";
        this.popupSet.content = "음성파일이 존재하지 않습니다.";
        this.popupSet.cancelBtnText = null;
        this.popupSet.confirmBtnText = "확인";
        return;
      }
      console.log("where :", where);
      console.log("parentIndex :", parentIndex);
      console.log("index :", index);

      window.open("https://devadmin.swallow.pro" + audioUrl, "_blank");
    },
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    async reloadData(value) {
      this.view.voicePrepared = false;
      await this.FETCH_TRAINING_HERTZ(
        "?userId=" + this.$route.query.userId + "&date=" + value
      ).then(data => {
        // 세트 갯수 = 표 최대 갯수
        let maxSet = data.response.settingSet;
        // 좌측 횟수 최대 갯수
        let maxNumber = data.response.settingNumber;
        // 실제 화면에 보여줄 객체
        let list;
        console.log("maxSet :", maxSet);
        if (maxSet > 1) {
          list = {
            // 아점저 / 세트별로 구분
            morning: [
              { set: 1, list: [] },
              { set: 2, list: [] }
            ],
            afternoon: [
              { set: 1, list: [] },
              { set: 2, list: [] }
            ],
            evening: [
              { set: 1, list: [] },
              { set: 2, list: [] }
            ]
          };
        } else {
          list = {
            // 아점저 / 세트별로 구분
            morning: [{ set: 1, list: [] }],
            afternoon: [{ set: 1, list: [] }],
            evening: [{ set: 1, list: [] }]
          };
        }
        /* - list [x : morning, afternoon, evening]

        * 1 2 3 4 5 6 7 8 9 10 11 <- list > x > list > second
        1 x x x x x x x x x x  x  <- list > x > list > hertz
        2
        3
        4
        5
        6
        7
        8
        9
        10 <- list > x > list > number
         */

        for (let i = 0; i < data.response.morning.length; i++) {
          this.view.firstHide = false;
          let item = data.response.morning[i];
          let set = list.morning[item.set - 1];
          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            set.list.push({
              number: item.list[j].number,
              audio: item.list[j].audio,
              second: [],
              hertz: []
            });
            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];
              set.list[j].hertz.push(hertzItem.hertz);
              set.list[j].second.push(hertzItem.second);
            }
          }
        }
        for (let i = 0; i < data.response.lunch.length; i++) {
          this.view.secondHide = false;
          let item = data.response.lunch[i];
          let set = list.afternoon[item.set - 1];
          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            set.list.push({
              number: item.list[j].number,
              audio: item.list[j].audio,
              second: [],
              hertz: []
            });
            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];
              set.list[j].hertz.push(hertzItem.hertz);
              set.list[j].second.push(hertzItem.second);
            }
          }
        }
        console.log("이브닝첵 :", data.response.evening.length);
        for (let i = 0; i < data.response.evening.length; i++) {
          this.view.thirdHide = false;
          let item = data.response.evening[i];
          let set = list.evening[item.set - 1];
          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            set.list.push({
              number: item.list[j].number,
              audio: item.list[j].audio,
              second: [],
              hertz: []
            });
            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];
              set.list[j].hertz.push(hertzItem.hertz);
              set.list[j].second.push(hertzItem.second);
            }
          }
        }
        if (data.response.morning.length < 1) {
          this.view.firstHide = true;
        }
        if (data.response.lunch.length < 1) {
          this.view.secondHide = true;
        }
        if (data.response.evening.length < 1) {
          this.view.thirdHide = true;
        }
        console.log("Object.key :", Object.keys(list)[0]);
        console.log("Object-item :", list[Object.keys(list)[0]][0]);
        this.result = list;
        this.view.voicePrepared = true;
      });
    },
    async getData() {
      await this.FETCH_TRAINING_HERTZ(
        `?userId=${this.$route.query.userId}&date=${this.$route.query.date}`
      ).then(data => {
        // 세트 갯수 = 표 최대 갯수
        let maxSet = data.response.settingSet;
        // 좌측 횟수 최대 갯수
        let maxNumber = data.response.settingNumber;
        // 실제 화면에 보여줄 객체
        let list;
        console.log("maxSet :", maxSet);
        if (maxSet > 1) {
          list = {
            // 아점저 / 세트별로 구분
            morning: [
              { set: 1, list: [] },
              { set: 2, list: [] }
            ],
            afternoon: [
              { set: 1, list: [] },
              { set: 2, list: [] }
            ],
            evening: [
              { set: 1, list: [] },
              { set: 2, list: [] }
            ]
          };
        } else {
          list = {
            // 아점저 / 세트별로 구분
            morning: [{ set: 1, list: [] }],
            afternoon: [{ set: 1, list: [] }],
            evening: [{ set: 1, list: [] }]
          };
        }

        /* - list [x : morning, afternoon, evening]

        * 1 2 3 4 5 6 7 8 9 10 11 <- list > x > list > second
        1 x x x x x x x x x x  x  <- list > x > list > hertz
        2
        3
        4
        5
        6
        7
        8
        9
        10 <- list > x > list > number
         */

        for (let i = 0; i < data.response.morning.length; i++) {
          this.view.firstHide = false;
          let item = data.response.morning[i];
          let set = list.morning[item.set - 1];
          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            set.list.push({
              number: item.list[j].number,
              audio: item.list[j].audio,
              second: [],
              hertz: []
            });
            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];
              set.list[j].hertz.push(hertzItem.hertz);
              set.list[j].second.push(hertzItem.second);
            }
          }
        }
        for (let i = 0; i < data.response.lunch.length; i++) {
          this.view.secondHide = false;
          let item = data.response.lunch[i];
          let set = list.afternoon[item.set - 1];
          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            set.list.push({
              number: item.list[j].number,
              audio: item.list[j].audio,
              second: [],
              hertz: []
            });
            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];
              set.list[j].hertz.push(hertzItem.hertz);
              set.list[j].second.push(hertzItem.second);
            }
          }
        }
        console.log("이브닝첵 :", data.response.evening.length);
        for (let i = 0; i < data.response.evening.length; i++) {
          this.view.thirdHide = false;
          let item = data.response.evening[i];
          let set = list.evening[item.set - 1];
          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            set.list.push({
              number: item.list[j].number,
              audio: item.list[j].audio,
              second: [],
              hertz: []
            });
            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];
              set.list[j].hertz.push(hertzItem.hertz);
              set.list[j].second.push(hertzItem.second);
            }
          }
        }
        if (data.response.morning.length < 1) {
          this.view.firstHide = true;
        }
        if (data.response.lunch.length < 1) {
          this.view.secondHide = true;
        }
        if (data.response.evening.length < 1) {
          this.view.thirdHide = true;
        }
        /** 
        for (let i = 0; i < data.response.lunch.length; i++) {
          let item = data.response.lunch[i];
          let set = list.afternoon[item.set - 1];

          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            let number = subitem.number;

            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];

              set.list.push({
                number: number,
                second: hertzItem.second,
                hertz: hertzItem.hertz
              });
            }
          }
        }

        for (let i = 0; i < data.response.evening.length; i++) {
          let item = data.response.evening[i];
          let set = list.evening[item.set - 1];

          for (let j = 0; j < item.list.length; j++) {
            let subitem = item.list[j];
            let number = subitem.number;

            for (let k = 0; k < subitem.hertzList.length; k++) {
              let hertzItem = subitem.hertzList[k];

              set.list.push({
                number: number,
                second: hertzItem.second,
                hertz: hertzItem.hertz
              });
            }
          }
        }
        */
        console.log("Object.key :", Object.keys(list)[0]);
        console.log("Object-item :", list[Object.keys(list)[0]][0]);
        this.result = list;
        this.view.voicePrepared = true;
      });
      /** API 조회하여 데이터를 info 에 주입 */
      /** 
      const getList = {
        morning: [
          {
            type: "raising",
            list: [
              {
                number: 1,
                set: 1,
                second: 1,
                hertz: 100
              },
              {
                number: 2,
                set: 1
              },
              {
                number: 3,
                set: 1
              },
              {
                number: 4,
                set: 1
              },
              {
                number: 5,
                set: 1
              },
              {
                number: 6,
                set: 1
              },
              {
                number: 7,
                set: 1
              },
              {
                number: 8,
                set: 1
              },
              {
                number: 9,
                set: 1
              },
              {
                number: 10,
                set: 1
              },
              {
                number: 1,
                set: 2
              },
              {
                number: 2,
                set: 2
              },
              {
                number: 3,
                set: 2
              },
              {
                number: 4,
                set: 2
              },
              {
                number: 5,
                set: 2
              },
              {
                number: 6,
                set: 2
              },
              {
                number: 7,
                set: 2
              },
              {
                number: 8,
                set: 2
              },
              {
                number: 9,
                set: 2
              },
              {
                number: 10,
                set: 2
              }
            ]
          }
        ],
        afternoon: [
          {
            type: "raising",
            list: [
              {
                number: 1,
                set: 1
              },
              {
                number: 2,
                set: 1
              },
              {
                number: 3,
                set: 1
              },
              {
                number: 4,
                set: 1
              },
              {
                number: 5,
                set: 1
              },
              {
                number: 6,
                set: 1
              },
              {
                number: 7,
                set: 1
              },
              {
                number: 8,
                set: 1
              },
              {
                number: 9,
                set: 1
              },
              {
                number: 10,
                set: 1
              },
              {
                number: 1,
                set: 2
              },
              {
                number: 2,
                set: 2
              },
              {
                number: 3,
                set: 2
              },
              {
                number: 4,
                set: 2
              },
              {
                number: 5,
                set: 2
              },
              {
                number: 6,
                set: 2
              },
              {
                number: 7,
                set: 2
              },
              {
                number: 8,
                set: 2
              },
              {
                number: 9,
                set: 2
              },
              {
                number: 10,
                set: 2
              }
            ]
          }
        ],
        evening: [
          {
            type: "raising",
            list: [
              {
                number: 1,
                set: 1
              },
              {
                number: 2,
                set: 1
              },
              {
                number: 3,
                set: 1
              },
              {
                number: 4,
                set: 1
              },
              {
                number: 5,
                set: 1
              },
              {
                number: 6,
                set: 1
              },
              {
                number: 7,
                set: 1
              },
              {
                number: 8,
                set: 1
              },
              {
                number: 9,
                set: 1
              },
              {
                number: 10,
                set: 1
              },
              {
                number: 1,
                set: 2
              },
              {
                number: 2,
                set: 2
              },
              {
                number: 3,
                set: 2
              },
              {
                number: 4,
                set: 2
              },
              {
                number: 5,
                set: 2
              },
              {
                number: 6,
                set: 2
              },
              {
                number: 7,
                set: 2
              },
              {
                number: 8,
                set: 2
              },
              {
                number: 9,
                set: 2
              },
              {
                number: 10,
                set: 2
              }
            ]
          }
        ]
      };
      this.result = getList;
      */
    },
    getMember() {
      this.FETCH_TRAINING_DATE(`?userId=${this.$route.query.userId}`).then(data => {
        console.log(data);
        const getList = [
          {
            question: 1,
            questionText: "회원명",
            answerResult: data.response.userName
          },
          {
            question: 2,
            questionText: "훈련일자",
            answerResultOpt: data.response.list
          }
        ];
        getList[1].answerResult = String(this.$route.query.date);
        this.member = getList;
      });
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    transTitle() {
      return indexNum => {
        console.log("indexNum :", indexNum);
        let mutatedValue;
        if (indexNum === 0) {
          mutatedValue = "아침";
        } else if (indexNum === 1) {
          mutatedValue = "점심";
        } else if (indexNum === 2) {
          mutatedValue = "저녁";
        }
        return mutatedValue;
      };
    },
    transTxt() {
      return text => {
        if (text === "morning") {
          return "아침";
        } else if (text === "afternoon") {
          return "점심";
        } else if (text === "evening") {
          return "저녁";
        }
      };
    }
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    vSelect
  }
};
</script>
<style lang="scss" scoped>
.member-info-set {
  width: 340px;
  margin: 0 0 85px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 1fr;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 1fr;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      display: flex;
      justify-content: center;
      align-items: center;
      .select-wrap {
        margin: 10px auto;
      }
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.voice-record {
  margin: 0 0 105px 0;
  .record-section {
    section {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0 0 0;
      &.hide {
        display: none;
      }
      &:first-of-type {
        margin: 0;
      }
      h3.title {
        width: 100%;
        margin: 0 0 50px 0;
        padding-left: 36px;
        @include initfontKor(
          $font-size: 28px,
          $line-height: 48px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .record-wrap {
        width: calc(50% - 30px);
        margin: 0 0 0 60px;
        position: relative;
        &:before {
          content: "2세트";
          position: absolute;
          top: -20px;
          left: 36px;
          width: calc(100% - 36px);
          transform: translateY(-50%);
          background: #fd8b11;
          @include initfontKor(
            $font-size: 20px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        &:first-of-type {
          margin: 0;
          &:before {
            content: "1세트";
          }
        }
        .second-wrap {
          margin: 0 0 0 auto;
          display: flex;
          .time-title {
            position: relative;
            width: 70px;
            padding: 0 0 0 36px;
            height: 42px;
            span {
              @include initfontKor(
                $font-size: 12px,
                $line-height: 24px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
            .right-direction {
              position: absolute;
              top: 0;
              right: 2px;
              display: block;
            }
            .left-direction {
              position: absolute;
              bottom: 0;
              left: 36px;
              display: block;
            }
          }
          .second-box {
            width: calc(100% - 70px);
            display: flex;
            .second {
              background: #fff9f3;
              border: 0px solid #acb3c9;
              border-width: 1px 1px 1px 0px;
              width: calc(100% / 14);
              &:first-of-type {
                margin: 0;
                border-width: 1px 1px 1px 1px;
              }
              span {
                display: block;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 40px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #292a2b,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
          }
        }
        .record {
          display: flex;
          align-items: center;
          &.first {
            margin: 0;
            .order-area .number-wrap .number span {
              border-width: 1px 1px 1px 1px;
            }
          }
          margin: 0px;
          .order-area {
            width: 70px;
            .number-wrap {
              .number {
                position: relative;
                display: flex;

                span {
                  background: #fff9f3;
                  border: 0px solid #acb3c9;
                  border-width: 0px 1px 1px 1px;
                  display: block;
                  width: calc(100% - 36px);
                  @include initfontKor(
                    $font-size: 16px,
                    $line-height: 40px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #292a2b,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                }
                .img-wrap {
                  align-items: center;
                  cursor: pointer;
                }
              }
            }
          }
          .detail-area {
            width: calc(100% - 70px);
            display: flex;
            flex-wrap: wrap;

            .hertz-wrap {
              width: 100%;
              display: flex;

              .hertz {
                width: calc(100% / 14);
                border: 0px solid #acb3c9;
                border-width: 0px 1px 1px 0px;
                &:last-of-type {
                }
                span {
                  display: block;
                  @include initfontKor(
                    $font-size: 16px,
                    $line-height: 40px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #292a2b,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                }
              }
            }
          }
        }
      }
    }
  }
  /**
  margin: 0 0 105px 0;
  .member-info-set {
    width: 340px;
    margin: 0 0 85px 0;
    .header {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 125px 1fr;
      margin: 0 0 1px 0;
      .item {
        padding: 0;
        outline: 1px solid #acb3c9;
        p {
          background: #f3f3f3;
          padding: 8px 12px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
    .content {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 125px 1fr;
      .item {
        padding: 0;
        outline: 1px solid #acb3c9;
        display: flex;
        justify-content: center;
        align-items: center;
        .select-wrap {
          margin: 10px auto;
        }
        p {
          padding: 8px 12px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
  }
  .record-area {
    .record-set {
      margin: 50px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      &:first-of-type {
        margin: 0;
      }
      h5.title {
        width: 100%;
        @include initfontKor(
          $font-size: 29px,
          $line-height: 60px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .record-box {
        margin: 0 0 0 40px;
        width: calc(50% - 20px);
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: repeat(auto-fill, 65px);
        &:first-of-type {
          margin: 0;
        }
        .record {
          h6.header {
            outline: 1px solid #acb3c9;
            background: #f3f3f3;
            margin: 0 0 1px 0;
            @include initfontKor(
              $font-size: 21px,
              $line-height: 34px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
          .content {
            outline: 1px solid #acb3c9;
            p {
              @include initfontKor(
                $font-size: 21px,
                $line-height: 34px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
          }
        }
      }
    }
  }
  */
}
</style>
