<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="member-info-set">
          <div class="title-wrap">
            <h5 class="title">인적사항</h5>
            <div class="add-member-btn-wrap">
              <button
                type="button"
                @click="searchUserPop()"
                v-text="listInfo.data.id === '' ? '회원선택' : '회원변경'"
              >
                회원추가
              </button>
            </div>
          </div>
          <div class="header">
            <div class="item" v-for="(header, index) in member" :key="index + 'A'">
              <p v-text="header.questionText" />
            </div>
          </div>
          <div class="content">
            <div class="item" v-for="(value, index) in member" :key="index + 'B'">
              <p v-text="value.answerResult" />
            </div>
          </div>
        </div>
        <div class="consultant-info">
          <h5 class="title">상담자 정보</h5>
          <div class="header">
            <div class="item">
              <p>이름</p>
            </div>
            <div class="item">
              <p>상태</p>
            </div>
          </div>
          <div class="content">
            <div class="item">
              <p v-text="consultant.choicedConsultantNm"  v-if="this.userNM === '' || consultant.choicedConsultantNm !== ''"/>
            </div>
            <div class="item">
              <p
                class="status"
                v-text="consultant.choicedConsultant === '' ? '수정' : '수정'"
                @click="callSetConsultantPop()"
              ></p>
            </div>
          </div>
        </div>
        <div class="reservation-info">
          <h5 class="title">예약정보</h5>
          <div class="list-holder">
            <list
              :columnInfo="columnInfo"
              :listInfo="listInfo"
              @call-write-pop="callWritePop"
              @call-calender-pop="callCalendarPop"
              @call-cancle-pop="callCanclePop"
              @typecall="typecall"
              v-if="isListVisible"
              ref="list"
            ></list>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
          <button type="button" class="doResv" @click="storeResv()">예약하기</button>
        </div>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @choiced-consultant="consultantCarry"
      @api-called="deleteResvApi"
      @transed-pop-data="getRequestContent"
      @transtype="getTransType"
    />
    <search-popup v-if="checkSearchPopup" @get-member-id="applyMember" />
    <calendar-popup
      @selected-resv-data="selectedResv"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import CalendarPopup from '@/components/modal/CalendarPopup';
import SearchPopup from '@/components/modal/SearchPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      popupSetCalendar: {},
      tree: {
        title: '원격 상담',
        detail: '예약 관리 및 상담 내역',
        detail2: '예약 상세 내역',
      },
      member: [
        {
          questionText: '회원명',
          answerResult: '',
        },
        {
          questionText: '성별',
          answerResult: '',
        },
        {
          questionText: '생년월일',
          answerResult: '',
        },
        {
          questionText: '연락처',
          answerResult: '',
        },
      ],
      consultant: {
        consultantList: [],
        choicedConsultantNm: '',
        choicedConsultant: '',
        choicedConsultantId: '',
        list: [],
      },
      listInfo: {
        type: 18,
        data: {
          id: '',
          userId: '',
          consultationDate: '',
          consultationTime: '',
          state: null,
          requestContent: '',
          type: null,
        },
      },
      columnInfo: [
        /**
         * 0: 음성
         * 1: 영상
         */
        {
          word: '상담 유형',
          filter: 'consultationType',
        },
        {
          word: '예약일',
          filter: 'consultationDate',
        },
        {
          word: '예약시간',
          filter: 'consultationTime',
        },
        {
          word: '상담 희망 내용',
          filter: 'hopeConts',
        },
        /**
         * 0: 예약완료
         * 1: 회원 취소
         * 2: 관리자 취소
         * 3: 관리자 예약 변경
         * 4: 상담완료
         */
        {
          word: '관리',
          filter: 'statusChange',
        },
      ],
      /** api Param */
      requestParam: '',
      selectedConsultant: '',
      isListVisible: true,
      userId: '',
      userNM: '',
      reserveType: null,
      checkListConsultant: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.fetchData();
  },
  methods: {
    ...mapActions('member', ['FETCH_CONSULT_LIST']),
    ...mapActions('reservation', [
      'FETCH_CONSULTATION',
      'UPDATE_CONSULTATION',
      'STORE_CONSULTATION',
    ]),
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP', 'SET_SEARCH_POPUP']),
    checkConsultant() {
      for (let i = 0; i < this.consultant.list.length; i++) {
        if (this.consultant.list[i].id === localStorage.userId) {
          this.consultant.choicedConsultant = localStorage.userName;
          this.consultant.choicedConsultantId = localStorage.userId;
          this.consultant.choicedConsultantNm = `${localStorage.userName}(${localStorage.userId})`;
        }
      }
    },
    async requestComplex() {
      await this.setResv();
      await this.setConsultant();
    },
    async setResv() {
      try {
        const payload = {
          type: this.listInfo.data.type,
          userId: this.listInfo.data.userId,
          consultationDate: this.listInfo.data.consultationDate,
          consultationTime: this.listInfo.data.consultationTime,
          requestContent: this.listInfo.data.requestContent,
        };
        await this.STORE_CONSULTATION(payload).then((data) => {
          this.listInfo.data.id = data.response.id;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    async setConsultant() {
      const payload = {
        id: this.listInfo.data.id,
        state: 0,
        consultationDate: this.listInfo.data.consultationDate,
        consultationTime: this.listInfo.data.consultationTime,
        consultantId: this.consultant.choicedConsultantId,
        consultant: this.consultant.choicedConsultant,
        type: 1,
      };
      if (this.consultant.choicedConsultantId === null || this.consultant.choicedConsultantId === '') {
        payload.consultantId = this.userId;
        payload.consultant = this.userNM;
      }
      await this.UPDATE_CONSULTATION(payload).then((data) => {
        if (data.result) {
          (this.$refs.list.result.consultationDate = this.listInfo.data.consultationDate),
          (this.$refs.list.result.consultationTime = this.listInfo.data.consultationTime),
          this.successPop();
        }
      });
    },
    storeResv() {
      if (this.checkFlag()) {
        this.requestComplex();
      }
    },
    checkFlag() {
      if (
        this.member[0].answerResult === ''
        || this.listInfo.data.consultationDate === ''
        || this.listInfo.data.type === null
        || this.consultant.choicedConsultant === ''
      ) {
        if (this.member[0].answerResult === '') {
          this.SET_POPUP(true);
          this.popupSet = {};
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '예약할 회원을 선택해주세요';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
        } else if (this.consultant.choicedConsultant === '') {
          this.SET_POPUP(true);
          this.popupSet = {};
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '상담자를 지정해주세요';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
        } else if (this.listInfo.data.consultationDate === '') {
          this.SET_POPUP(true);
          this.popupSet = {};
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '예약시간을 설정해주세요';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
        } else if (this.listInfo.data.type === null) {
          this.SET_POPUP(true);
          this.popupSet = {};
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '상담유형을 설정해주세요';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
        }
        return false;
      }
      return true;
    },
    getRequestContent(content) {
      console.log('content :', content);
      this.listInfo.data.requestContent = content;
    },
    initResvInfo2() {
      this.isListVisible = false;
      this.listInfo.data.type = null;
    },
    getTransType(type) {
      console.log('type : ', type);
      this.initResvInfo2();
      setTimeout(() => {
        this.isListVisible = true;
        this.listInfo.data.type = type;
      }, 1);
    },
    searchUserPop() {
      this.SET_SEARCH_POPUP(true);
    },
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async fetchData() {
      // 상담자 목록 설정
      await this.FETCH_CONSULT_LIST('?page=1').then((data) => {
        console.log(data.response.list);
        const list = [];
        for (let i = 0; i < data.response.list.length; i++) {
          data.response.list[
            i
          ].user_conm = `${data.response.list[i].user_name}(${data.response.list[i].id})`;
          list.push(data.response.list[i].user_conm);
        }
        this.consultant.consultantList = list;
        this.consultant.list = data.response.list;
      });
      this.checkConsultant();
    },
    deleteResvApi() {
      /** 삭제할 예약의 value 를 서버에 전송(아마도  ID값) */
      const data = {
        id: this.$route.params.code,
        state: 2,
      };
      this.UPDATE_CONSULTATION(data).then((data) => {
        if (data.result) {
          this.SET_POPUP(true);
          this.popupSet = {};
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '예약이 취소 되었습니다.';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
          this.popupSet.returnList = true;
          // this.popupSet.nextLink = '/ReservationList';
        }
      });
    },
    applyMember(index, item) {
      console.log('index :', index, 'item :', item);
      this.member[0].answerResult = item.userName;
      this.member[1].answerResult = item.gender;
      this.member[2].answerResult = item.birthDate;
      this.member[3].answerResult = item.tel;
      this.listInfo.data.userId = item.id;
    },
    successPop() {
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.content = '예약이 완료되었습니다.';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/reservationList';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    initResvInfo() {
      this.isListVisible = false;
      this.listInfo.data.consultationDate = '';
      this.listInfo.data.consultationTime = '';
      this.listInfo.data.requestContent = '';
      this.listInfo.data.state = null;
    },
    selectedResv(dataSet) {
      console.log('데이터', dataSet);
      this.initResvInfo();
      setTimeout(() => {
        this.isListVisible = true;
        this.listInfo.data.consultationDate = dataSet.date;
        this.listInfo.data.consultationTime = dataSet.time;
        this.listInfo.data.state = 0;
      }, 1);
    },
    callCalendarPop(info) {
      console.log('가져온 날짜정보', info);
      /** 가져온 날짜로 달력 API 조회 */
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.notificationType = 1;
      this.popupSetCalendar.isTimeTable = true;
    },
    typecall(info) {
      console.log('가져온 info : ', info);
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popType = 'type';
      this.popupSet.dataValue = this.reserveType;
      this.popupSet.typeFlag = false;
      this.popupSet.typeFlag2 = false;
      this.popupSet.content = '내용을 입력해주세요.';
      this.popupSet.cancelBtnText = '';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '선택완료';
    },
    callCanclePop(info) {
      console.log('텍스트박스 정보 기입', info);
      /** 가저온 날짜로 해당 예약내역 취소 API 실행 */
      /** api 조회 */
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '예약을 취소하시겠습니까?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '예';
    },
    callWritePop(info) {
      console.log('텍스트박스 정보 기입', info);
      /** 가저온 정보를 텍스트박스에 기입 */
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popType = 'write';
      this.popupSet.dataValue = this.listInfo.data.requestContent;
      this.popupSet.content = '내용을 입력해주세요.';
      this.popupSet.cancelBtnText = '입력취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '입력완료';
    },
    consultantCarry(info) {
      console.log('info-carryed :', info);
      const consultantInfo = this.consultant.list.find((e) => {
        if (e.user_conm === info) {
          console.log('e :', e);
          this.consultant.choicedConsultant = e.user_name;
          this.consultant.choicedConsultantNm = e.user_conm;
          this.consultant.choicedConsultantId = e.id;
        }
      });
    },
    callSetConsultantPop() {
      console.log('list');
      /** 리스트 정리
       * '
       */
      this.SET_POPUP(true);
      this.popupSet.popType = 'setConsultant';
      this.popupSet.consultOpt = this.consultant;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup', 'checkSearchPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
    SearchPopup,
    CalendarPopup,
  },
};
</script>
<style lang="scss" scoped>
.member-info-set {
  margin: 0 0 85px 0;
  .title-wrap {
    position: relative;
    h5.title {
      width: fit-content;
    }
    .add-member-btn-wrap {
      position: absolute;
      top: 0;
      left: 125px;
      button {
        width: 105px;
        padding: 0;
        background: #292a2b;
        margin: 0px auto;
        cursor: pointer;
        border: 0;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 90px 180px 180px;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      min-height: 42px;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 90px 180px 180px;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      min-height: 42px;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.consultant-info {
  margin: 0 0 85px 0;
  width: 310px;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 140px 1fr;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 140px 1fr;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        &.status {
          width: 105px;
          line-height: 32px;
          padding: 0;
          background: #292a2b;
          color: #fff;
          margin: 8px auto;
          cursor: pointer;
        }
      }
    }
  }
}
.reservation-info {
  width: 1000px;
  margin: 0 auto 140px 0;
  .list-holder {
    min-height: 99px;
    .header {
      .item:first-of-type {
        width: 120%;
      }
    }
    .content {
      .item:first-of-type {
        width: 120%;
      }
    }
  }
}
.bottom-btn-wrap {
  button.doResv {
    background: #fd8b11 !important;
  }
}
</style>
